<template>
  <div>我是授权页面</div>
</template>

<script>
import { localGet, localRemove } from "@/common/utils";
import { onMounted } from "vue";
import { useRouter } from "vue-router";
import axios from "@/common/axios";
import api from "@/common/api/api";
export default {
  name: "oauth",
  setup() {
    const router = useRouter();
    onMounted(() => {
      // console.log("oauth", location);
      addApplication(formatData(location.search));
    });
    const formatData = () => {
      let params = location.search.split("?")[1];
      // console.log(location.search);
      // console.log(params.split("&"));
      let paramsMap = new Map();
      params.split("&").forEach((item) => {
        paramsMap.set(item.split("=")[0], item.split("=")[1]);
      });
      // console.log(paramsMap.get("code"));
      let applicationValue =
        paramsMap.get("value") || localGet("applicationValue");
      console.log("applicationValue: ", applicationValue);
      let paramsObj = {
        type: applicationValue && applicationValue.split("IDAAS")[0],
        appId: applicationValue && applicationValue.split("IDAAS")[1],
        code: paramsMap.get("code"),
        shopId: paramsMap.get("shop_id"),
        mainAccountId: paramsMap.get("main_account_id"),
        state: paramsMap.get("state"),
        shop: paramsMap.get("shop"),
      };
      console.log(paramsObj);
      localRemove("applicationValue");
      return paramsObj;
    };
    const isShopify = (shop) => {
      return shop && shop.endsWith(".myshopify.com");
    };
    const addApplication = (value) => {
      console.log(value);
      console.log(router);
      const queryData = {
        type: value.type,
        code: value.code,
        id: value.appId,
        shopId: value.shopId,
        mainAccountId: value.mainAccountId,
        shop: value.shop,
        state: value.state,
      };
      //如果是shopify回调，直接调用接口
      if (isShopify(value.shop)) {
        axios
          .post(api.saveMerchantApp, {
            code: value.code,
            state: value.state,
            shop: value.shop,
            merchantApp: {},
          })
          .then(() => {
            if (value.state === "fromOMS") {
              //从OMS跳转过来
              window.location.href = window.location.origin.includes("test")
                ? "https://testoms.geezdata.com/"
                : "https://intl.oms.geezdata.com/";
            } else {
              router.push({
                name: "applicationList",
              });
            }
          });
        return;
      }

      if (value.type === "reAuth" || localGet("reAuth")) {
        router.push({
          name: "applicationList",
          query: { ...queryData, type: "reAuth" },
        });
      } else {
        queryData.value = JSON.stringify({
          appId: value.appId,
        });
        router.push({
          name: "saveApplication",
          query: queryData,
        });
      }
    };
    return {
      addApplication,
      formatData,
    };
  },
};
</script>

<style></style>
